import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core'
import { Creators as AuthActions } from 'store/ducks/Auth'
import { ExitToApp, ExpandMore, Person, Settings } from '@material-ui/icons'
import { useHistory } from 'react-router'

import { getUser } from '../../util'
import IntlMessages from '../../util/IntlMessages'

const UserInfo = () => {
  const [anchorElement, setAnchorElement] = useState(null)
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false)

  const user = getUser()

  const dispatch = useDispatch()
  const history = useHistory()

  const handleUserMenu = event => {
    setIsUserMenuOpen(true)
    setAnchorElement(event.currentTarget.children[1])
  }

  const handleRequestClose = () => {
    setIsUserMenuOpen(false)
  }

  return (
    <div className="user-profile">
      <Person />

      <div className="user-detail">
        <button type="button" className="user-name" onClick={handleUserMenu}>
          <span className="text-truncate">
            {JSON.parse(user).name || 'User'}
          </span>
          <ExpandMore />
        </button>
      </div>
      <Menu
        className="user-info"
        id="simple-menu"
        anchorEl={anchorElement}
        open={isUserMenuOpen}
        onClose={handleRequestClose}
        elevation={0}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          disableRipple
          onClick={() => {
            handleRequestClose()
            history.push('/account')
          }}
        >
          <ListItemIcon style={{ minWidth: '40px' }}>
            <Settings fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <IntlMessages id="user.account" />
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleRequestClose()
            dispatch(AuthActions.logout())
          }}
        >
          <ListItemIcon style={{ minWidth: '40px' }}>
            <ExitToApp fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <IntlMessages id="logout" />
          </ListItemText>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default UserInfo
