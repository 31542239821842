export const Types = {
  // GET LIST OF ALARM DATA
  GET_ALARM_DATA_DASHBOARD_REQUEST: 'GET_ALARM_DATA_DASHBOARD_REQUEST',
  GET_ALARM_DATA_MONITORING_REQUEST: 'GET_ALARM_DATA_MONITORING_REQUEST',
  GET_ALARM_DATA_HISTORY_REQUEST: 'GET_ALARM_DATA_HISTORY_REQUEST',
  GET_ALARM_DATA_SUCCESS: 'GET_ALARM_DATA_SUCCESS',
  GET_ALARM_DATA_FAIL: 'GET_ALARM_DATA_FAIL',
  GET_ALARM_DATA_CLEAN: 'GET_ALARM_DATA_CLEAN',

  // GET LIST OF HISTORY DATA
  GET_HISTORY_DATA_DASHBOARD_REQUEST: 'GET_HISTORY_DATA_DASHBOARD_REQUEST',
  GET_HISTORY_DATA_MONITORING_REQUEST: 'GET_HISTORY_DATA_MONITORING_REQUEST',
  GET_HISTORY_DATA_REQUEST: 'GET_HISTORY_DATA_REQUEST',
  GET_HISTORY_DATA_SUCCESS: 'GET_HISTORY_DATA_SUCCESS',
  GET_HISTORY_DATA_FAIL: 'GET_HISTORY_DATA_FAIL',
  CLEAN_HISTORY_DATA: 'CLEAN_HISTORY_DATA',
}

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
}

// AlarmData Reducer
export function alarmData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_ALARM_DATA_DASHBOARD_REQUEST:
    case Types.GET_ALARM_DATA_MONITORING_REQUEST:
    case Types.GET_ALARM_DATA_HISTORY_REQUEST:
      return { ...state, loading: true, error: false }
    case Types.GET_ALARM_DATA_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: false }
    case Types.GET_ALARM_DATA_FAIL:
      return { ...state, loading: false, error: true }
    case Types.GET_ALARM_DATA_CLEAN:
      return { ...state, loading: false, data: null, error: false }
    default:
      return state
  }
}

// HistoryData Reducer
export function historyData(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_HISTORY_DATA_REQUEST:
      return { ...state, loading: true, error: false }
    case Types.GET_HISTORY_DATA_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: false }
    case Types.GET_HISTORY_DATA_FAIL:
      return { ...state, loading: false, error: true }
    case Types.CLEAN_HISTORY_DATA:
      return { ...state, data: null, loading: false, error: false }
    default:
      return state
  }
}

export const Creators = {
  getAlarmDataDashboardRequest: payload => ({
    type: Types.GET_ALARM_DATA_DASHBOARD_REQUEST,
    payload,
  }),
  getAlarmDataMonitoringRequest: payload => ({
    type: Types.GET_ALARM_DATA_MONITORING_REQUEST,
    payload,
  }),
  getAlarmDataHistoryRequest: payload => ({
    type: Types.GET_ALARM_DATA_HISTORY_REQUEST,
    payload,
  }),
  getAlarmDataSuccess: payload => ({
    type: Types.GET_ALARM_DATA_SUCCESS,
    payload,
  }),
  getAlarmDataFail: () => ({ type: Types.GET_ALARM_DATA_FAIL }),
  getAlarmDataClean: () => ({ type: Types.GET_ALARM_DATA_CLEAN }),

  getHistoryDataRequest: payload => ({
    type: Types.GET_HISTORY_DATA_REQUEST,
    payload,
  }),
  getHistoryDataSuccess: payload => ({
    type: Types.GET_HISTORY_DATA_SUCCESS,
    payload,
  }),
  getHistoryDataFail: () => ({ type: Types.GET_HISTORY_DATA_FAIL }),
  cleanHistoryData: () => ({ type: Types.CLEAN_HISTORY_DATA }),
}
