import { NotificationManager } from 'react-notifications'
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import { Creators as UserActions, Types as UserTypes } from 'store/ducks/User'
import api from 'util/Api'
import { parseError } from 'util/index'

function* getUsers({ payload }) {
  try {
    const { data, status } = yield call(api.get, '/users', payload)
    if (status === 200) {
      yield put(UserActions.getUsersSuccess(data))
    } else {
      parseError(data || 'Erro ao carregar usuário.')
      yield put(UserActions.getUsersFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar usuário.')
    yield put(UserActions.getUsersFail())
  }
}

function* putUser({ payload }) {
  try {
    const { data, status } = yield call(api.put, '/update', payload)
    if (status === 200) {
      NotificationManager.success('Usuário editado com sucesso.')
      yield put(UserActions.putUserSuccess())
    } else {
      parseError(data || 'Erro ao editar usuário.')
      yield put(UserActions.putUserFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao editar usuário.')
    yield put(UserActions.putUserFail())
  }
}

function* postUser({ payload }) {
  try {
    const { data, status } = yield call(api.post, '/register', payload)
    if (status === 201) {
      NotificationManager.success('Usuário criado com sucesso.')
      yield put(UserActions.postUserSuccess())
    } else {
      parseError(data || 'Erro ao criar usuário.')
      yield put(UserActions.postUserFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao criar usuário.')
    yield put(UserActions.postUserFail())
  }
}

function* getRoles({ payload }) {
  try {
    const { data, status } = yield call(api.get, '/roles', payload)
    if (status === 200) {
      yield put(UserActions.getRolesSuccess(data))
    } else {
      parseError(data || 'Erro ao listar papéis.')
      yield put(UserActions.getRolesFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao listar papéis.')
    yield put(UserActions.getRolesFail())
  }
}

function* getNotificationOptions() {
  try {
    const { data, status } = yield call(api.get, '/notifications/options')
    if (status === 200) {
      yield put(UserActions.getNotificationOptionsSuccess(data))
    } else {
      parseError(data || 'Erro ao listar opções de notificação.')
      yield put(UserActions.getNotificationOptionsFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao listar opções de notificação.')
    yield put(UserActions.getNotificationOptionsFail())
  }
}

function* showUser({ payload }) {
  try {
    const { status, data } = yield call(
      api.get,
      `/users/${payload}?with=roles,teams,notifications`,
    )
    if (status === 200) {
      yield put(UserActions.showUserSuccess(data))
    } else {
      parseError(data || 'Erro ao carregar usuário.')
      yield put(UserActions.showUserFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar usuário.')
    yield put(UserActions.showUserFail())
  }
}

function* getUsersByCustomer({ payload }) {
  try {
    const { status, data } = yield call(
      api.get,
      `/customers/${payload.id}/users`,
      payload,
    )
    if (status === 200) {
      yield put(UserActions.getUsersByCustomerSuccess(data))
    } else {
      parseError(data || 'Erro ao carregar usuários.')
      yield put(UserActions.getUsersByCustomerFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar usuários.')
    yield put(UserActions.getUsersByCustomerFail())
  }
}

function* deleteUser({ payload }) {
  try {
    const { data, status } = yield call(api.delete, `/users/${payload}`)
    if (status === 200) {
      NotificationManager.success('Usuário deletado com sucesso.')
      yield put(UserActions.deleteUserSuccess())
    } else {
      parseError(data || 'Erro ao deletar o usuário.')
      yield put(UserActions.deleteUserFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao deletar o usuário.')
    yield put(UserActions.deleteUserFail())
  }
}

function* updateById({ payload }) {
  try {
    const { data, status } = yield call(
      api.put,
      `/updateById/${payload.id}`,
      payload.data,
    )
    if (status === 200) {
      NotificationManager.success('Usuário editado com sucesso.')
      yield put(UserActions.updateUserByIdSuccess())
    } else {
      parseError(data || 'Erro ao ediar o usuário.')
      yield put(UserActions.updateUserByIdFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao ediar o usuário.')
    yield put(UserActions.updateUserByIdFail())
  }
}

function* getMyUser() {
  try {
    const { data, status } = yield call(api.get, '/myUser', {
      params: { with: 'roles,teams,notifications' },
    })
    if (status === 200) {
      yield put(UserActions.getMyUserSuccess(data))
    } else {
      parseError(data || 'Erro ao carregar o usuário.')
      yield put(UserActions.getMyUserFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar o usuário.')
    yield put(UserActions.getMyUserFail())
  }
}

function* putMyUser({ payload }) {
  try {
    const { data, status } = yield call(api.put, '/update', payload.data)
    if (status === 200) {
      NotificationManager.success('Usuário editado com sucesso.')
      yield put(UserActions.putMyUserSuccess(data)) // Passe `data` ao `putMyUserSuccess`
    } else {
      parseError(data || 'Erro ao editar o usuário.')
      yield put(UserActions.putMyUserFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao editar o usuário.')
    yield put(UserActions.putMyUserFail())
  }
}

function* getUsersWatcher() {
  yield takeLatest(UserTypes.GET_USERS_REQUEST, getUsers)
}

function* putUserWatcher() {
  yield takeLatest(UserTypes.PUT_USER_REQUEST, putUser)
}

function* postUserWatcher() {
  yield takeLatest(UserTypes.POST_USER_REQUEST, postUser)
}

function* getRolesWatcher() {
  yield takeLatest(UserTypes.GET_ROLES_REQUEST, getRoles)
}

function* getNotificationOptionsWatcher() {
  yield takeLatest(
    UserTypes.GET_NOTIFICATION_OPTIONS_REQUEST,
    getNotificationOptions,
  )
}

function* showUserWatcher() {
  yield takeLatest(UserTypes.SHOW_USER_REQUEST, showUser)
}

function* getUsersByCustomerWatcher() {
  yield takeLatest(UserTypes.GET_USERS_BY_CUSTOMER_REQUEST, getUsersByCustomer)
}

function* deleteUserWatcher() {
  yield takeLatest(UserTypes.DELETE_USER_REQUEST, deleteUser)
}

function* updateByIdWatcher() {
  yield takeLatest(UserTypes.UPDATE_USER_BY_ID_REQUEST, updateById)
}

function* getMyUserWatcher() {
  yield takeLatest(UserTypes.GET_MY_USER_REQUEST, getMyUser)
}

function* putMyUserWatcher() {
  yield takeLatest(UserTypes.PUT_MY_USER_REQUEST, putMyUser)
}

export default function* rootSaga() {
  yield all([
    fork(getUsersWatcher),
    fork(putUserWatcher),
    fork(postUserWatcher),
    fork(getRolesWatcher),
    fork(getNotificationOptionsWatcher),
    fork(getUsersByCustomerWatcher),
    fork(showUserWatcher),
    fork(deleteUserWatcher),
    fork(updateByIdWatcher),
    fork(getMyUserWatcher),
    fork(putMyUserWatcher),
  ])
}
