import { NotificationManager } from 'react-notifications'

const INTERVAL_TIME = 300

const getToken = () => localStorage.getItem('FRIGOCENTER.ACCESS_TOKEN')

const getTypeUser = () => localStorage.getItem('FRIGOCENTER.TYPE_USER')

const getUser = () => localStorage.getItem('FRIGOCENTER.USER')

const removeTokens = () => {
  localStorage.clear()
  localStorage.removeItem('FRIGOCENTER.ACCESS_TOKEN')
  localStorage.removeItem('FRIGOCENTER.TYPE_USER')
}

const setTokens = (access, roles, userId, userName) => {
  const user = { id: userId, name: userName }

  localStorage.setItem('FRIGOCENTER.ACCESS_TOKEN', access)
  localStorage.setItem('FRIGOCENTER.TYPE_USER', roles)
  localStorage.setItem('FRIGOCENTER.USER', JSON.stringify(user))
}

const removeTypeUser = () => {
  localStorage.removeItem('FRIGOCENTER.TYPE_USER')
}

const handleCurrency = (value, name, setValue) => {
  const price = `${parseFloat(value).toLocaleString(
    'pt-BR',
    {
      style: 'currency',
      currency: 'BRL',
    },
    { minimumFractionDigits: 2 },
  )}`

  setValue(name, price)
}

const parseError = error => {
  const message = error?.response?.data?.message
  if (typeof message === 'object') {
    if (error?.response?.data?.message?.length) {
      error.response.data.message.forEach(e => {
        NotificationManager.error(e)
      })
    } else if (error?.response?.data?.message) {
      NotificationManager.error(error?.response?.data?.message)
    } else if (error?.message?.lengt) {
      error.message.forEach(e => {
        NotificationManager.error(e)
      })
    } else {
      NotificationManager.error(error?.message)
    }
  } else {
    NotificationManager.error(message)
  }
}

const phoneMask = raw => {
  const number = raw.replace(/\D/g, '')
  if (number.length > 10) {
    return [
      /\d/,
      /\d/,
      ' ',
      /[0-9]?/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]
  }
  return [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
}

export {
  getToken,
  removeTokens,
  removeTypeUser,
  handleCurrency,
  getTypeUser,
  parseError,
  setTokens,
  phoneMask,
  getUser,
  INTERVAL_TIME,
}
