import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import {
  Types as AlarmDataTypes,
  Creators as AlarmDataActions,
} from 'store/ducks/AlarmData'
import api from 'util/Api'
import { parseError } from 'util/index'

// AlarmData Sagas
function* getAlarmDataDashboard({ payload }) {
  try {
    const { data, status } = yield call(
      api.get,
      '/alarmData/dashboard',
      payload,
    )
    if (status === 200) {
      yield put(AlarmDataActions.getAlarmDataSuccess(data))
    } else {
      parseError(data || 'Erro ao atualizar o alarme.')
      yield put(AlarmDataActions.getAlarmDataFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao atualizar o alarme.')
    yield put(AlarmDataActions.getAlarmDataFail())
  }
}

function* getAlarmDataMonitoring({ payload }) {
  try {
    const { data, status } = yield call(
      api.get,
      '/alarmData/monitoring',
      payload,
    )
    if (status === 200) {
      yield put(AlarmDataActions.getAlarmDataSuccess(data))
    } else {
      parseError(data || 'Erro ao atualizar o alarme.')
      yield put(AlarmDataActions.getAlarmDataFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao atualizar o alarme.')
    yield put(AlarmDataActions.getAlarmDataFail())
  }
}

// HistoryData Sagas
function* getHistoryData({ payload }) {
  try {
    const { data, status } = yield call(api.get, '/alarmData/history', payload)
    if (status === 200) {
      yield put(AlarmDataActions.getHistoryDataSuccess(data))
    } else {
      parseError(data || 'Erro ao carregar o histórico de alarmes.')
      yield put(AlarmDataActions.getHistoryDataFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar o histórico de alarmes.')
    yield put(AlarmDataActions.getHistoryDataFail())
  }
}

function* getAlarmDataWatcher() {
  yield takeLatest(
    AlarmDataTypes.GET_ALARM_DATA_DASHBOARD_REQUEST,
    getAlarmDataDashboard,
  )
  yield takeLatest(
    AlarmDataTypes.GET_ALARM_DATA_MONITORING_REQUEST,
    getAlarmDataMonitoring,
  )
  yield takeLatest(
    AlarmDataTypes.GET_ALARM_DATA_HISTORY_REQUEST,
    getHistoryData,
  )
}

function* getHistoryDataWatcher() {
  yield takeLatest(AlarmDataTypes.GET_HISTORY_DATA_REQUEST, getHistoryData)
}

export default function* rootSaga() {
  yield all([fork(getAlarmDataWatcher), fork(getHistoryDataWatcher)])
}
