export const Types = {
  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_FAIL: 'GET_USERS_FAIL',

  PUT_USER_REQUEST: 'PUT_USER_REQUEST',
  PUT_USER_SUCCESS: 'PUT_USER_SUCCESS',
  PUT_USER_FAIL: 'PUT_USER_FAIL',
  PUT_USER_CLEAN: 'PUT_USER_CLEAN',

  POST_USER_REQUEST: 'POST_USER_REQUEST',
  POST_USER_SUCCESS: 'POST_USER_SUCCESS',
  POST_USER_FAIL: 'POST_USER_FAIL',
  POST_USER_CLEAN: 'POST_USER_CLEAN',

  GET_ROLES_REQUEST: 'GET_ROLES_REQUEST',
  GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
  GET_ROLES_FAIL: 'GET_ROLES_FAIL',

  GET_NOTIFICATION_OPTIONS_REQUEST: 'GET_NOTIFICATION_OPTIONS_REQUEST',
  GET_NOTIFICATION_OPTIONS_SUCCESS: 'GET_NOTIFICATION_OPTIONS_SUCCESS',
  GET_NOTIFICATION_OPTIONS_FAIL: 'GET_NOTIFICATION_OPTIONS_FAIL',

  GET_USERS_BY_CUSTOMER_REQUEST: 'GET_USERS_BY_CUSTOMER_REQUEST',
  GET_USERS_BY_CUSTOMER_SUCCESS: 'GET_USERS_BY_CUSTOMER_SUCCESS',
  GET_USERS_BY_CUSTOMER_FAIL: 'GET_USERS_BY_CUSTOMER_FAIL',

  SHOW_USER_REQUEST: 'SHOW_USER_REQUEST',
  SHOW_USER_SUCCESS: 'SHOW_USER_SUCCESS',
  SHOW_USER_FAIL: 'SHOW_USER_FAIL',
  SHOW_USER_CLEAN: 'SHOW_USER_CLEAN',

  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAIL: 'DELETE_USER_FAIL',
  DELETE_USER_CLEAN: 'DELETE_USER_CLEAN',

  UPDATE_USER_BY_ID_REQUEST: 'UPDATE_USER_BY_ID_REQUEST',
  UPDATE_USER_BY_ID_SUCCESS: 'UPDATE_USER_BY_ID_SUCCESS',
  UPDATE_USER_BY_ID_FAIL: 'UPDATE_USER_BY_ID_FAIL',
  UPDATE_USER_BY_ID_CLEAN: 'UPDATE_USER_BY_ID_CLEAN',

  GET_MY_USER_REQUEST: 'GET_MY_USER_REQUEST',
  GET_MY_USER_SUCCESS: 'GET_MY_USER_SUCCESS',
  GET_MY_USER_FAIL: 'GET_MY_USER_FAIL',
  GET_MY_USER_CLEAN: 'GET_MY_USER_CLEAN',

  PUT_MY_USER_REQUEST: 'PUT_MY_USER_REQUEST',
  PUT_MY_USER_SUCCESS: 'PUT_MY_USER_SUCCESS',
  PUT_MY_USER_FAIL: 'PUT_MY_USER_FAIL',
  PUT_MY_USER_CLEAN: 'PUT_MY_USER_CLEAN',

  SET_USER_UPDATE_STATUS: 'SET_USER_UPDATE_STATUS',
}

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,

  isUserUpdated: false,

  putLoading: false,
  putError: false,
  putSuccess: false,

  postLoading: false,
  postError: false,
  postSuccess: false,

  roles: null,
  rolesLoading: false,
  rolesError: false,

  notificationOptions: null,
  notificationOptionsLoading: false,
  notificationOptionsError: false,

  usersByCustomer: null,
  usersByCustomerLoading: false,
  usersByCustomerError: false,

  showLoading: false,
  showError: false,
  showData: null,

  deleteLoading: false,
  deleteError: false,
  deleteSuccess: false,

  updateLoading: false,
  updateError: false,
  updateSuccess: false,

  myUserLoading: false,
  myUserError: false,
  myUserData: null,

  putMyUserLoading: false,
  putMyUserError: false,
  putMyUserSuccess: false,
}

export default function Users(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case Types.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case Types.GET_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      }

    case Types.PUT_USER_REQUEST:
      return {
        ...state,
        putLoading: true,
        putError: false,
        putSuccess: false,
      }
    case Types.PUT_USER_SUCCESS:
      return {
        ...state,
        putLoading: false,
        putError: false,
        putSuccess: true,
      }
    case Types.PUT_USER_FAIL:
      return {
        ...state,
        putLoading: false,
        putError: true,
        putSuccess: false,
      }
    case Types.PUT_USER_CLEAN:
      return {
        ...state,
        putLoading: false,
        putError: false,
        putSuccess: false,
      }

    case Types.POST_USER_REQUEST:
      return {
        ...state,
        postLoading: true,
        postError: false,
        postSuccess: false,
      }
    case Types.POST_USER_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postError: false,
        postSuccess: true,
      }
    case Types.POST_USER_FAIL:
      return {
        ...state,
        postLoading: false,
        postError: true,
        postSuccess: false,
      }
    case Types.POST_USER_CLEAN:
      return {
        ...state,
        postLoading: false,
        postError: false,
        postSuccess: false,
      }

    case Types.GET_ROLES_REQUEST:
      return {
        ...state,
        rolesLoading: true,
        rolesError: false,
      }
    case Types.GET_ROLES_SUCCESS:
      return {
        ...state,
        rolesLoading: false,
        roles: action.payload,
      }
    case Types.GET_ROLES_FAIL:
      return {
        ...state,
        rolesLoading: false,
        rolesError: true,
      }

    case Types.GET_NOTIFICATION_OPTIONS_REQUEST:
      return {
        ...state,
        notificationOptionsLoading: true,
        notificationOptionsError: false,
      }
    case Types.GET_NOTIFICATION_OPTIONS_SUCCESS:
      return {
        ...state,
        notificationOptionsLoading: false,
        notificationOptions: action.payload,
      }
    case Types.GET_NOTIFICATION_OPTIONS_FAIL:
      return {
        ...state,
        notificationOptionsLoading: false,
        notificationOptionsError: true,
      }

    case Types.GET_USERS_BY_CUSTOMER_REQUEST:
      return {
        ...state,
        usersByCustomerLoading: true,
        usersByCustomerError: false,
      }
    case Types.GET_USERS_BY_CUSTOMER_SUCCESS:
      return {
        ...state,
        usersByCustomerLoading: false,
        usersByCustomer: action.payload,
      }
    case Types.GET_USERS_BY_CUSTOMER_FAIL:
      return {
        ...state,
        usersByCustomerLoading: false,
        usersByCustomerError: true,
      }

    case Types.SHOW_USER_REQUEST:
      return {
        ...state,
        showLoading: true,
        showError: false,
      }
    case Types.SHOW_USER_SUCCESS:
      return {
        ...state,
        showLoading: false,
        showError: false,
        showData: action.payload,
      }
    case Types.SHOW_USER_FAIL:
      return {
        ...state,
        showLoading: false,
        showError: true,
      }
    case Types.SHOW_USER_CLEAN:
      return {
        ...state,
        showLoading: false,
        showError: false,
        showData: null,
      }

    case Types.DELETE_USER_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteError: false,
        deleteSuccess: false,
      }
    case Types.DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: true,
      }
    case Types.DELETE_USER_FAIL:
      return {
        ...state,
        deleteLoading: false,
        deleteError: true,
        deleteSuccess: false,
      }
    case Types.DELETE_USER_CLEAN:
      return {
        ...state,
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: false,
      }

    case Types.UPDATE_USER_BY_ID_REQUEST:
      return {
        ...state,
        putLoading: true,
        putError: false,
        putSuccess: false,
      }
    case Types.UPDATE_USER_BY_ID_SUCCESS:
      return {
        ...state,
        putLoading: false,
        putError: false,
        putSuccess: true,
      }
    case Types.UPDATE_USER_BY_ID_FAIL:
      return {
        ...state,
        putLoading: false,
        putError: true,
        putSuccess: false,
      }
    case Types.UPDATE_USER_BY_ID_CLEAN:
      return {
        ...state,
        putLoading: false,
        putError: false,
        putSuccess: false,
      }

    case Types.SET_USER_UPDATE_STATUS:
      return {
        ...state,
        isUserUpdated: action.payload,
      }

    case Types.GET_MY_USER_REQUEST:
      return {
        ...state,
        myUserLoading: true,
        myUserError: false,
      }
    case Types.GET_MY_USER_SUCCESS:
      return {
        ...state,
        myUserLoading: false,
        myUserError: false,
        myUserData: action.payload,
      }
    case Types.GET_MY_USER_FAIL:
      return {
        ...state,
        myUserLoading: false,
        myUserError: true,
      }
    case Types.GET_MY_USER_CLEAN:
      return {
        ...state,
        myUserLoading: false,
        myUserError: false,
        myUserData: null,
      }

    case Types.PUT_MY_USER_REQUEST:
      return {
        ...state,
        putMyUserLoading: true,
        putMyUserError: false,
        putMyUserSuccess: false,
      }
    case Types.PUT_MY_USER_SUCCESS:
      return {
        ...state,
        putMyUserLoading: false,
        putMyUserError: false,
        putMyUserSuccess: true,
        myUserData: action.payload,
      }
    case Types.PUT_MY_USER_FAIL:
      return {
        ...state,
        putMyUserLoading: false,
        putMyUserError: true,
        putMyUserSuccess: false,
      }
    case Types.PUT_MY_USER_CLEAN:
      return {
        ...state,
        putMyUserLoading: false,
        putMyUserError: false,
        putMyUserSuccess: false,
      }

    default:
      return state
  }
}

export const Creators = {
  // GET
  getUsersRequest: payload => ({
    type: Types.GET_USERS_REQUEST,
    payload,
  }),
  getUsersSuccess: payload => ({
    type: Types.GET_USERS_SUCCESS,
    payload,
  }),
  getUsersFail: () => ({
    type: Types.GET_USERS_FAIL,
  }),

  // SET

  setUserUpdateStatus: status => ({
    type: Types.SET_USER_UPDATE_STATUS,
    payload: status,
  }),

  // PUT
  putUserRequest: payload => ({
    type: Types.PUT_USER_REQUEST,
    payload,
  }),
  putUserSuccess: payload => ({
    type: Types.PUT_USER_SUCCESS,
    payload,
  }),
  putUserFail: () => ({
    type: Types.PUT_USER_FAIL,
  }),
  putUserClean: () => ({
    type: Types.PUT_USER_CLEAN,
  }),

  // POST
  postUserRequest: payload => ({
    type: Types.POST_USER_REQUEST,
    payload,
  }),
  postUserSuccess: payload => ({
    type: Types.POST_USER_SUCCESS,
    payload,
  }),
  postUserFail: () => ({
    type: Types.POST_USER_FAIL,
  }),
  postUserClean: () => ({
    type: Types.POST_USER_CLEAN,
  }),

  // GET ROLES
  getRolesRequest: payload => ({
    type: Types.GET_ROLES_REQUEST,
    payload,
  }),
  getRolesSuccess: payload => ({
    type: Types.GET_ROLES_SUCCESS,
    payload,
  }),
  getRolesFail: () => ({
    type: Types.GET_ROLES_FAIL,
  }),

  // GET NOTIFICATION OPTIONS
  getNotificationOptionsRequest: payload => ({
    type: Types.GET_NOTIFICATION_OPTIONS_REQUEST,
    payload,
  }),
  getNotificationOptionsSuccess: payload => ({
    type: Types.GET_NOTIFICATION_OPTIONS_SUCCESS,
    payload,
  }),
  getNotificationOptionsFail: () => ({
    type: Types.GET_NOTIFICATION_OPTIONS_FAIL,
  }),

  // GET USERS BY CUSTOMER ID AND TEAMS
  getUsersByCustomerRequest: payload => ({
    type: Types.GET_USERS_BY_CUSTOMER_REQUEST,
    payload,
  }),
  getUsersByCustomerSuccess: payload => ({
    type: Types.GET_USERS_BY_CUSTOMER_SUCCESS,
    payload,
  }),
  getUsersByCustomerFail: () => ({
    type: Types.GET_USERS_BY_CUSTOMER_FAIL,
  }),

  // SHOW
  showUserRequest: payload => ({
    type: Types.SHOW_USER_REQUEST,
    payload,
  }),
  showUserSuccess: payload => ({
    type: Types.SHOW_USER_SUCCESS,
    payload,
  }),
  showUserFail: () => ({
    type: Types.SHOW_USER_FAIL,
  }),
  showUserClean: () => ({
    type: Types.SHOW_USER_CLEAN,
  }),

  // DELETE
  deleteUserRequest: payload => ({
    type: Types.DELETE_USER_REQUEST,
    payload,
  }),
  deleteUserSuccess: payload => ({
    type: Types.DELETE_USER_SUCCESS,
    payload,
  }),
  deleteUserFail: () => ({
    type: Types.DELETE_USER_FAIL,
  }),
  deleteUserClean: () => ({
    type: Types.DELETE_USER_CLEAN,
  }),

  // UPDATE BY ID
  updateUserByIdRequest: payload => ({
    type: Types.UPDATE_USER_BY_ID_REQUEST,
    payload,
  }),
  updateUserByIdSuccess: payload => ({
    type: Types.UPDATE_USER_BY_ID_SUCCESS,
    payload,
  }),
  updateUserByIdFail: () => ({
    type: Types.UPDATE_USER_BY_ID_FAIL,
  }),
  updateUserByIdClean: () => ({
    type: Types.UPDATE_USER_BY_ID_CLEAN,
  }),

  // GET MY USER
  getMyUserRequest: payload => ({
    type: Types.GET_MY_USER_REQUEST,
    payload,
  }),
  getMyUserSuccess: payload => ({
    type: Types.GET_MY_USER_SUCCESS,
    payload,
  }),
  getMyUserFail: () => ({
    type: Types.GET_MY_USER_FAIL,
  }),
  getMyUserClean: () => ({
    type: Types.GET_MY_USER_CLEAN,
  }),

  putMyUserRequest: payload => ({
    type: Types.PUT_MY_USER_REQUEST,
    payload,
  }),
  putMyUserSuccess: payload => ({
    type: Types.PUT_MY_USER_SUCCESS,
    payload,
  }),
  putMyUserFail: () => ({
    type: Types.PUT_MY_USER_FAIL,
  }),
  putMyUserClean: () => ({
    type: Types.PUT_MY_USER_CLEAN,
  }),
}
